export default {
  home: {
    minute: 'minuto',
    price_info: 'Gratis durante los primeros {mianfei} minutos. Un cargo diario máximo de {currencyName}{fengding}. El depósito se deducirá automáticamente cuando el alquiler alcance {currencyName}{yajin}',
    feature_1: 'Adaptado a todo tipo de móviles',
    feature_2: 'Se puede devolver a cualquier otro sitio',
    feature_3: 'Diseño especial: carga inalámbrica (solo para módulos compatibles)',
    tips_1: 'Su depósito de {currencyName}{yajin} le será reembolsado después de que devuelva el banco de energía.',
    // tips_1: 'Es necesario recargar un depósito de {currencyName}{yajin} antes de alquilar si el saldo es insuficiente',
    tips_2: 'El depósito de recarga {currencyName}{yajin} se puede alquilar, se puede reembolsar el agotado',
    tips_3: 'El saldo actual es {currencyName}{myyue}, que se puede arrendar directamente',
    submit: 'Comenzar alquiler',
    borrow: 'Alquilar',
    msg_fail_title: 'Error',
    msg_fail_content: 'Error en el alquiler, inténtelo de nuevo',
    msg_rentcount: 'Actualmente tienes {zujieNum} pedidos que no están cerrados',
    goborrow: '¡¡¡Ve a cargar!!!',
    nearby: 'Ver puntos de venta cercanos',
    preauth: 'Preautorización'
  },
  login: {
    code_title: 'Verificación por SMS',
    phone_number: 'Número de teléfono',
    verification_code: 'Ingrese el código de verificación por SMS',
    captcha_code: 'Código',
    send_code: 'Enviar código',
    login: 'Acceso',
    agree: 'Al hacer clic en Iniciar sesión, aceptas ',
    readme: `User Agreement`,
    msg_fail_login: 'Error de inicio de sesion',
    msg_success_login: 'Inicio de sesión correcto',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    sign_in_with: 'Inicia sesión con',
    no_sms: 'No recibí el mensaje de texto',
    retry: 'Reenviar'
  },
  confirm: {
    title: 'Confirmación de pago',
    paynow: 'Pago inmediato',
    SMSConfirm: 'Pague de acuerdo con el mensaje de texto recibido en su teléfono y haga clic en el botón confirmar para continuar.',
    pay_label: 'Cantidad',
    auth_label: 'Preautorización',
    price_tips: 'Su saldo puede ser retirado si así lo solicita. Es posible que tarde entre 1 y 5 días hábiles en aparecer en su cuenta de pagos.',
    auth_tips: 'Es obligatorio un importe de autorización previa de {0}{1}. El importe del alquiler real se deducirá cuando se devuelva el powerbank.',
    pay_type: 'Seleccione su método de pago preferido',
    submit: 'Confirmar pago',
    msg_loading: 'Cargando...',
    msg_fail_title: 'Advertencia',
    msg_fail_payment: 'Error al iniciar el pago del depósito',
    msg_fail_order: 'Error al crear la orden de arrendamiento',
    msg_fail_support: 'El sistema no es compatible',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Reintentar',
    msg_fail_cancel: 'Cancelar',
    noPhone: "Please enter phone number"
  },
  mine: {
    title: 'Perfil del usuario',
    wallet: 'Mi billetera',
    balance: 'Mi balance',
    deposit: 'Depósito',
    wallet_in: 'Cargar',
    wallet_out: 'Retirar',
    wallet_log: 'Registro de billetera',
    order: 'Orden',
    coupon: 'Cupón',
    login_tips: 'Por favor Iniciar sesión',
    msg_fail_title: 'Error',
    msg_fail_update: 'Error en la modificación, inténtelo de nuevo',
    msg_loading: 'Cargando...',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    help: 'Ayuda',
    setting: 'Configuración',
    logout: 'Cerrar sesión',
    buy: 'Comprar equipo'
  },
  msg: {
    no_phone: 'El número de teléfono es requerido',
    loading: 'Cargando...',
    title: 'Pista',
    success: 'Operación exitosa',
    error: 'Error desconocido, inténtelo de nuevo más tarde.',
    roleerror: 'Permisos insuficientes, consulte al administrador',
    neterror: 'Error en la conexión de red. Por favor, inténtelo de nuevo más tarde',
    confirm: 'Confirmar',
    download: "Download",
    retry: 'Reintentar',
    cancel: 'Cancelar',
    title_fail: 'Advertencia',
    vieworder: 'Ver pedido',
    success_payment: 'Pago completado',
    success_buy: 'Compra completada',
    fail_payment: 'Error en el pago',
    keyword: 'palabra clave',
    ispayorder: '¿Confirma si se ha realizado el pago?',
    unpay: 'No pagado',
    yespay: 'Pagado',
    notopen: 'Aún no está abierto',
    appoffline: 'La aplicación aún no se ha iniciado, espere'
  },
  result: {
    title: 'Alquiler confirmado',
    tips: '¡Tu Powerbank está listo, tómalo y disfruta de tu día, sin estrés!',
    gotit: 'Entiendo',
    price_plan: 'Estándar de carga',
    msg_success: 'Alquilado con éxito',
    msg_tips_loading: 'La batería portátil está apareciendo, espere pacientemente...',
    msg_result_loading: 'El resultado del pago se está confirmando...',
    msg_fail_confirm: 'Confirmar',
    btn_usage: 'Nota de uso',
    btn_return: 'Nota de devolución',
    deposit: 'Depósito',
    msg_fail_content: 'Solicitud fallida, inténtelo de nuevo',
    price_label: 'por hora',
    price_info: 'Sin cargo durante los primeros {mianfei} minutos, un cargo máximo diario de {currency}{finding}, el depósito se deducirá automáticamente cuando el alquiler alcance {ccurrency}{yajin}',
    tips_1: 'Recoja el banco de energía <span class="number">No.{number}</span> como se muestra en la imagen',
    tips_2: 'Por favor, ordene los cables e inserte el banco de energía hasta el fondo con la <span class="highlight">[flecha hacia abajo]</span> cuando regrese',
    msg_note_usage: 'El banco de energía está equipado con 3 tipos de cables y cambio inalámbrico, utilícelo según sea necesario.',
    msg_note_return: 'Por favor, ordene estos cables e inserte el banco de energía hasta el fondo con la [flecha hacia abajo] cuando regrese.',
    return_info: 'Al regresar, coloque bien los cables e inserte el lado con virutas de metal en un enchufe vacío hasta que llegue al fondo y se estabilice.'
  },
  result_msg: {
    loading: 'Cargando...',
    refresh: 'Actualizar'
  },
  order: {
    title: 'Orden de arrendamiento',
    in_use: 'En uso',
    ended: 'Terminado',
    msg_error: 'Solicitud fallida'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Lugar alquilado',
    time_rented: 'Tiempo alquilado',
    venue_returned: 'Lugar devuelto',
    time_returned: 'El tiempo regresó',
    charge_standard: 'Estándar de carga',
    charge_standard_info: '{pJifei} {pCurrency} por hora (primeros {pMian} minutos gratis)',
    rent_occurred: 'Se produjo el alquiler',
    returned: 'Devuelto',
    unreturned: 'No devuelto',
    type_0: 'Sin completar',
    type_1: 'Alquiler',
    type_2: 'Cancelado',
    type_3: 'Devuelto',
    type_4: 'Ocurrió un error',
    type_5: 'Con el tiempo',
    min: 'Mín',
    payment: 'Pago'
  },
  setting: {
    title: 'Configuración',
    lang: 'Idioma'
  },
  help: {
    title: 'PREGUNTAS MÁS FRECUENTES'
  },
  wallet_in: {
    title: 'Recarga de saldo',
    price_label: 'Cantidad de recarga',
    price_placeholder: 'Por favor ingrese el monto de la recarga',
    balance: 'Saldo actual',
    price_auto: 'Elegir monto de pago',
    price_tips: 'Su saldo puede ser retirado si así lo solicita. Es posible que tarde entre 1 y 5 días hábiles en aparecer en su cuenta de pagos.',
    submit: 'Entregar',
    loading: 'Cargando...',
    paying: 'Ir a la página de pago...',
    msg_title: 'Consejos',
    msg_fail_order: 'Error al crear la orden de recarga',
    msg_fail_payment: 'Error al iniciar el pago de recarga',
    msg_fail_retry: 'Reintentar'
  },
  wallet_out: {
    title: 'Retiro',
    price_label: 'Cantidad de retiro',
    price_placeholder: 'Por favor ingrese el monto del retiro',
    balance: 'Saldo actual',
    balance_all: 'Retirar todo',
    deposit: 'Depósito',
    deposit_tips: 'Hay una orden de arrendamiento. El depósito de Ksh{pyajin} no es reembolsable',
    submit: 'Entregar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    loading: 'Cargando...',
    msg_fail_price: 'El monto del retiro es incorrecto',
    msg_fail_balance: 'El monto del retiro no puede ser mayor que el saldo actual',
    msg_fail_submit: 'Por favor complete la información completa',
    msg_title: 'Error',
    msg_success_content: 'Aplicar reembolso exitoso',
    confirmtitle: 'Consejos',
    confirmcontent: 'La billetera actual no admite retiros de efectivo. Tenga en cuenta su información de contacto y realice el reembolso manualmente',
    payee_name: 'Nombre del beneficiario',
    payee_bankcard: 'Número de tarjeta bancaria',
    payee_phone: 'Teléfono',
    cancelText: 'Cancelar',
    confirmText: 'Volver a aplicar'
  },
  map: {
    title: 'Cerca del equipo',
    result_msg: 'No se encontró ningún equipo cerca',
    msg_title: 'error',
    no_support: '¡Lo siento! Su navegador no admite ubicación',
    my_position: 'mi ubicacion',
    distance: 'distancia:',
    shop_time: 'horas',
    free: 'prestable',
    return: 'retornable',
    shop_title: 'Detalles del comerciante',
    address: 'DIRECCIÓN',
    go_here: 'Ven aquí',
    '在线': 'en línea',
    '离线': 'fuera de línea'
  }
}
