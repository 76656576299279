import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */'../views/login/login.vue'),
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/homeMap',
    name: 'homeMap',
    component: () => import(/* webpackChunkName: "homeMap" */'../views/homeMap/homeMap.vue'),
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_wechat',
    name: 'login_wechat',
    component: () => import(/* webpackChunkName: "LoginWechat" */'../views/login/wechat.vue'),
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_alipay',
    name: 'login_alipay',
    component: () => import(/* webpackChunkName: "LoginAlipay" */'../views/login/alipay.vue'),
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "About" */'../views/about.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "Confirm" */'../views/confirm/confirm.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "Wallet" */'../views/wallet/wallet.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "Result" */'../views/result/result.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "Order" */'../views/order/order.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "Setting" */'../views/setting/setting.vue'),
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "Help" */'../views/help/help.vue')
  },
  {
    path: '/wallet_in',
    name: 'wallet_in',
    component: () => import(/* webpackChunkName: "WalletIn" */'../views/wallet/wallet_in.vue')
  },
  {
    path: '/wallet_out',
    name: 'wallet_out',
    component: () => import(/* webpackChunkName: "WalletOut" */'../views/wallet/wallet_out.vue')
  },
  {
    path: '/wallet_list',
    name: 'wallet_list',
    component: () => import(/* webpackChunkName: "WalletList" */'../views/wallet/wallet_list.vue')
  },
  {
    path: '/google_map',
    name: 'google_map',
    component: () => import(/* webpackChunkName: "GoogleMap" */'../views/map/google-index.vue'),
    alias: '/map'
  },
  {
    path: '/nearby_shop',
    name: 'nearby_shop',
    component: () => import(/* webpackChunkName: "ShopDetail" */'../views/nearby/shop'),
    meta: {
      ignoreAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router
